import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {
  private triggerAnalyticsData = new Subject<string>();
  triggerAnalyticsData$ = this.triggerAnalyticsData.asObservable();
  triggerResetData = new Subject<string>();
  triggerResetData$ = this.triggerResetData.asObservable();
  private selectedImageSubject = new Subject<string | ArrayBuffer | null>();
  private selectedBgSubject = new Subject<string | ArrayBuffer | null>();
  private selectedLogoSubject = new Subject<string | ArrayBuffer | null>();
  private selectedVideoSubject = new Subject<[] | ArrayBuffer | 'null'>();
  private selectedOtherImagesSubject = new Subject<[] | ArrayBuffer | 'null'>();
  private uploadedUserProfileSubject = new Subject<string | ArrayBuffer | null>();
  private uploadedQrImageSubject = new Subject<string | ArrayBuffer | null>();
  private userProfileNameSubject = new Subject<string | ArrayBuffer | null>();
  private imageIndexSubject = new Subject<number[] | null>();
  private videoIndexSubject = new Subject<number[] | null>();

  private clickSource = new Subject<any>();
  click$ = this.clickSource.asObservable();

  emitClickEvent(func?: any) {
    this.clickSource.next(func ? func : true);
  }

  triggerAnalytics(variable: string) {
    this.triggerAnalyticsData.next(variable);
  }

  getTriggerAnalytics(): Observable<string> {
    return this.triggerAnalyticsData;
  }

  setTriggerResetData(variable: string) {
    this.triggerResetData.next(variable);
  }

  getTriggerResetData(): Observable<string> {
    return this.triggerResetData;
  }

  setProfileSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedImageSubject.next(imageData);
  }

  getProfileSelectedImage() {
    return this.selectedImageSubject.asObservable();
  }

  setBgSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedBgSubject.next(imageData);
  }

  getBgSelectedImage() {
    return this.selectedBgSubject.asObservable();
  }

  setLogoSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedLogoSubject.next(imageData);
  }

  getLogoSelectedImage() {
    return this.selectedLogoSubject.asObservable();
  }

  setSelectedVideo(videoData: any | null) {
    if(videoData !== null){
      this.selectedVideoSubject.next(videoData);
    }
  }

  getSelectedVideo() {
    return this.selectedVideoSubject.asObservable();
  }

  setOtherImagesSelectedImage(imageData: any | null) {
    if (imageData !== null) {
      this.selectedOtherImagesSubject.next(imageData);
    }
  }

  getOtherImagesSelectedImage() {
    return this.selectedOtherImagesSubject.asObservable();
  }

  setImageIndex(images: number[] | null) {
    if (images !== null && Array.isArray(images)) {
      this.imageIndexSubject.next(images);
    }
  }

  getImageIndex() {
    return this.imageIndexSubject.asObservable();
  }

  setVideoIndex(images: number[] | null) {
    if (images !== null && Array.isArray(images)) {
      this.videoIndexSubject.next(images);
    }
  }

  getVideoIndex() {
    return this.videoIndexSubject.asObservable();
  }

  defaultStyle(dData: any, tempFormData: any){
    tempFormData.themeColor = dData.card_data.themeColor;
    tempFormData.backgroundColor = dData.card_data.backgroundColor;
    tempFormData.cardColor = dData.card_data.cardColor;
    tempFormData.textColor = dData.card_data.textColor;
    tempFormData.highlightedColor = dData.card_data.highlightedColor;
    tempFormData.iconColor = dData.card_data.iconColor;
  }

  setUserProfileImage(imageData: any | null){
    this.uploadedUserProfileSubject.next(imageData);
  }

  getUserProfileImage(){
    return this.uploadedUserProfileSubject.asObservable();
  }

  setQRImage(imageData: any | null){
    this.uploadedQrImageSubject.next(imageData);
  }

  getQRImage(){
    return this.uploadedQrImageSubject.asObservable();
  }

  setUserProfileName(data: any | null){
    this.userProfileNameSubject.next(data);
  }

  getUserProfileName(){
    return this.userProfileNameSubject.asObservable();
  }

  settingUpdate(cardData: any, tempFormData: any){
    if(!cardData?.v_card?.card_data && tempFormData.email=='email@gmail.com'){
      tempFormData.email = cardData?.email;
    }
    if(!cardData?.v_card?.card_data && tempFormData.firstName=='Name' && tempFormData.lastName==''){
      tempFormData.firstName = cardData?.first_name;
      tempFormData.lastName = cardData?.last_name;
    }
  }

  hasThreeValues(tempFormData: any): boolean {
    const socialMedia = [
      tempFormData?.whatsapp,
      tempFormData?.facebook,
      tempFormData?.instagram,
      tempFormData?.linkedin,
      tempFormData?.twitter
    ];

    // Filter to count truthy values
    const filledFieldsCount = socialMedia.filter(field => !!field).length;

    // Return true if 3 or more fields are filled
    return filledFieldsCount >= 3;
  }

  qrcode(mainqr: string, updatedQr?: any){
    if (!updatedQr) {
      return mainqr;  // Return the mainqr if updatedQr is null or undefined
    }

    if ((!updatedQr.sticker_id || updatedQr.sticker_id === 0) && (!updatedQr.shape_id || updatedQr.shape_id === 0)) {
      return mainqr;
    } else {
      if (updatedQr.shape_id && updatedQr.shape_id !== 0) {
        if (updatedQr.sticker_id && updatedQr.sticker_id !== 0) {
          return updatedQr.image_sticker;
        } else {
          return updatedQr.image_url_shape;
        }
      } else {
        return updatedQr.image_sticker;
      }
    }
  }

  calculateCurrentDayStatus(data: any): boolean {
    const currentDate = new Date();
    const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' }); // Get current day
    const currentTime = currentDate.toTimeString().slice(0, 5); // Get current time (HH:mm)

    // Find the matching day in the data.days array
    const today = data.days?.find((day: any) => day.id == currentDay.toLowerCase() && day.checked);
    if (today) {
      // Check if the current time is within the open and close time
      if (currentTime >= today.openTime && currentTime <= today.closeTime) {
       return true;
      } else {
        return false;
      }
    }
    return false;
  }

  getValidLinks(linkArray: Array<{ linkName: string; linkUrl: string }>) {
    return linkArray?.filter(link => link.linkName || link.linkUrl);
  }

  getValidTestimonial(testimonialArray: Array<{ testimonialText: string; name: string; designation: string }>){
    return testimonialArray?.filter(link => link.testimonialText || link.name || link.designation);
  }

  getValidSocialLink(socialLinkArray: Array<any>) {
    return socialLinkArray?.filter(socialLink => {
      return Object.keys(socialLink).some(key => {
        if (key === 'whatsapp') {
          return socialLink[key] || socialLink['whatsappPrefix']; 
        }
        return socialLink[key];
      });
    });
  }

  validData(data: any){
    if(data?.panels){
      data.panels.forEach((panelItem: any) => {
        if(panelItem.type === 'links'){
          panelItem.filteredLinks = this.getValidLinks(panelItem.linkArray);
        }
        if(panelItem.type ==='testimonial'){
          panelItem.filteredData = this.getValidTestimonial(panelItem.testimonialArray);
        }
        if(panelItem.type ==='socialLink'){
          panelItem.filteredSocialLink = this.getValidSocialLink(panelItem.socialLinkArray);
        }
        if(panelItem.type === 'businessHour'){
          panelItem.days.forEach((day: any) => {
            day.openTime = this.formatTime(day.openTime);
            day.closeTime = this.formatTime(day.closeTime);
          })
        }
      });
    }
  }

  formatTime(time: string): string {
    const date = new Date(time);
    const formatTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    return formatTime;
  }
}
